@import "_variables.scss";
@import "mixins.scss";

@mixin prismic_paragraph_before_list() {
  .prismic-paragraph:has(+ ul),
  .prismic-paragraph:has(+ ol) {
    margin-bottom: var(--chakra-space-6-4);
  }
}

.prismic-hyperlink {
  font-size: $font-size-reg;
  font-weight: $font-weight-bold;
  line-height: $line-height-xs;
  letter-spacing: 0.02em;
  text-align: left;
  color: $color-surface-text-blue;
  &:hover {
    text-decoration: underline;
    color: $color-btn-link-hover;
  }
}

ul.prismic-list {
  margin-top: 0;
  @include prismic_list_margin();
  @include inline-code();
}

ol.prismic-list {
  @include prismic_list_margin();
  @include inline-code();
}

ul.prismic-list > li,
ol.prismic-list > li {
  font-weight: $font-weight-regular;
  color: $color-font-card;
  font-size: $font-size-reg;
  line-height: $line-height-medium;
  @include inline-code();
}

ol.prismic-list > li {
  ul.prismic-list {
    margin-left: 0px !important;
    margin-top: var(--chakra-space-6-4) !important;
    li {
      margin-left: var(--chakra-space-9-6) !important;
    }
  }
}

ul.prismic-list > li {
  list-style-type: disc;
  ul.prismic-list > li {
    list-style-type: circle;
    ul.prismic-list > li {
      list-style-type: square;
      ul.prismic-list > li {
        list-style-type: disc;
      }
    }
  }
}

/*

--chakra-space-2-4: 0.6rem;
--chakra-space-3-2: 0.8rem;
--chakra-space-4-8: 1.2rem;
--chakra-space-5-6: 1.4rem;
--chakra-space-6-4: 1.6rem;
--chakra-space-7-2: 1.8rem;
--chakra-space-8-8: 2.2rem;
--chakra-space-9-6: 2.4rem;
--chakra-space-12-8: 3.2rem;
--chakra-space-18-4: 4.6rem;
--chakra-space-21-6: 5.4rem;
--chakra-space-25-6: 6.4rem;
--chakra-space-39-6: 9.7rem;

*/

@mixin prismic_rich_txt_heading_margins() {
  margin-bottom: var(--chakra-space-6-4);
}

.slice-item {
  .prismic-heading-two,
  .prismic-heading-three {
    &:last-child {
      @include prismic_rich_txt_heading_margins();
    }
  }
}

.prismic-heading-two {
  font-family: $font-family-bold;
  font-size: $font-size-l;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-xlarge;
  text-align: left;
  @include prismic_rich_txt_heading_margins();
  &:last-child {
    margin-bottom: 0;
  }
}
.prismic-heading-three {
  font-family: $font-family-bold;
  font-size: $font-size-2rem;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-large;
  text-align: left;
  @include prismic_rich_txt_heading_margins();
  &:last-child {
    margin-bottom: 0;
  }
}

.paragraph-slice {
  .prismic-paragraph {
    margin-bottom: var(--chakra-space-9-6);
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include prismic_paragraph_before_list();
}

@include prismic_paragraph_before_list();
.prismic-paragraph {
  font-family: $font-family-regular;
  font-size: $font-size-reg;
  font-weight: $font-weight-regular;
  line-height: $line-height-medium;
  color: $color-font-card;
  margin-bottom: var(--chakra-space-9-6);
  @include inline-code();
  &:last-child {
    margin-bottom: 0;
  }
}

.prismic-image {
  border-radius: var(--chakra-space-3-2);
  border: 1px solid $color-font-card;
  margin-bottom: var(--chakra-space-12-8);
}

.prismic-divider {
  border: 0.5px solid $color-border !important;
  margin-bottom: var(--chakra-space-9-6);
}

.tooltipDef {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  white-space: nowrap;
  z-index: 1000;
}
